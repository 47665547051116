import "./topbar.css"
import { Link } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { Context } from "../../context/Context";
import axios from "axios"
import { imageUrl } from "../../constants/Global";

export default function TopBar() {
    const { user, dispatch } = useContext(Context);
    const [open, setOpen] = useState(false);
    const PF = imageUrl + "/api/image/"

    const handleLogout = async (e) => {
        try {
            const res = await axios.post("/api/auth/logout")
            dispatch({ type: "LOGOUT" })
            setOpen(false)
        } catch (err) {
        }
    }

    const useOnClickOutside = (ref, handler) => {
        useEffect(() => {
          const listener = event => {
            if (!ref.current || ref.current.contains(event.target) || event.target.className === "burger" || event.target.className === "burgerLine") {
                return;
            }
            handler(event);
          };
          document.addEventListener("mousedown", listener);
      
          return () => {
            document.removeEventListener("mousedown", listener);
          };
        }, [ref, handler]);
    };

    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    return (
        <>
        <div className="top">
            <div className="topLeft">
                {/* <a href="https://www.facebook.com/naukozaciya"><i className="topIcon fab fa-facebook-square"></i></a>
                <a href="https://www.instagram.com/naukozaciya"><i className="topIcon fab fa-instagram-square"></i></a> */}
                <a href="https://vk.com/club209207289" aria-label="vk"><i className="topIcon fab fa-vk"></i></a>
                <a href="https://t.me/naukozaciya" aria-label="telegram"><i className="topIcon fab fa-telegram-plane"></i></a>
            </div>
            <div className="topCenter">
                <ul className="topList">
                    <li className="topListItem">
                        <Link className="link" to="/">ГЛАВНАЯ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/services">ПЛАТНЫЕ УСЛУГИ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/newAuthor">СТАНЬ АВТОРОМ</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/about">О ПРОЕКТЕ</Link>
                    </li>
                    {/* <li className="topListItem">
                        <Link className="link" to="/">КОНТАКТЫ</Link>
                    </li> */}
                    <li className="topListItem">
                        <Link className="link" to="/write">{user?.author === true && "НОВЫЙ ПОСТ"}</Link>
                    </li>
                    <li className="topListItem" onClick={handleLogout}>
                        {user && "ВЫХОД"}
                    </li>
                </ul>
            </div>
            <div className="topRight">
                {user ? (<Link to="/settings"><img className="topImg" src={PF + user.profilePicture} alt="Профиль" /></Link>) : (
                    <ul className="topList">
                        <li className="topListItem">
                            <Link className="link" to="/login">ВОЙТИ</Link>
                        </li>
                        {/* <li className="topListItem">
                            <Link className="link" to="/register">РЕГИСТРАЦИЯ</Link>
                        </li> */}
                    </ul>)}
                {/* <i className="topSearchIcon fas fa-search"></i> */}
                {open ? (
                <div className="burger" style={{ height: "16px" }} onClick={() => setOpen(false)}>
                    <div className="burgerLine" style={{ transform: "rotate(45deg)" }}></div>
                    <div className="burgerLine" style={{ opacity: "0", transform: "translateX(20px)" }}></div>
                    <div className="burgerLine" style={{ transform: "rotate(-45deg)" }}></div>
                </div>) : (
                    <div className="burger" onClick={() => setOpen(true)}>
                    <div className="burgerLine" style={{ transform: "rotate(0)" }}></div>
                    <div className="burgerLine" style={{ opacity: "1", transform: "translateX(0)" }}></div>
                    <div className="burgerLine" style={{ transform: "rotate(0)" }}></div>
                </div>
                )}
            </div>
        </div>
        {open ? (
            <div className="responsiveMenu" ref={node} style={{ "background-color": "#fdfbfb", transform: "translateY(0)", transition: "transform 0.3s ease-in-out" }}>
                <Link className="responsiveLink" to="/" onClick={() => setOpen(false)}>ГЛАВНАЯ</Link>
                <Link className="responsiveLink" to="/services" onClick={() => setOpen(false)}>ПЛАТНЫЕ УСЛУГИ</Link>
                <Link className="responsiveLink" to="/newAuthor" onClick={() => setOpen(false)}>СТАНЬ АВТОРОМ</Link>
                <Link className="responsiveLink" to="/about" onClick={() => setOpen(false)}>О ПРОЕКТЕ</Link>

                {user?.author === true && <Link className="responsiveLink" to="/write" onClick={() => setOpen(false)}>НОВЫЙ ПОСТ</Link>}
                {user ? (<Link className="responsiveLink" to="/settings" onClick={() => setOpen(false)}>ПРОФИЛЬ</Link>) : (
                <Link className="responsiveLink" to="/login" onClick={() => setOpen(false)}>ВОЙТИ</Link>)}
                {user && <div className="responsiveLink" onClick={handleLogout}>ВЫХОД</div>}
            </div>
        ) : <div className="responsiveMenu" style={{ transform: "translateY(-100%)", transition: "transform 0.3s ease-in-out" }}></div>}
        </>
    )
}