import "./write.css";
import { useContext, useState, useCallback, useMemo, useRef } from "react";
import axios from "axios";
import { Context } from "../../context/Context";
import imageCompression from "browser-image-compression";
import ReactQuill, { Quill } from "react-quill-new";
import "../../assets/css/quill-bubble.css";
import { imageUrl } from "../../constants/Global";
// import { Formats, Modules } from "../../constants/Quill";

export default function Write() {
    const [title, setTitle] = useState("");
    const [description, setDesc] = useState("");
    const [hashtags, setHashtags] = useState("");
    const [file, setFile] = useState(null);
    const { user } = useContext(Context);
    const [disabled, setDisabled] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [innerImages, setInnerImages] = useState([]);
    const PF = imageUrl + "/api/image/";

    const compressionOptions = useMemo(() => ({
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    }), []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        if (!title || title.trim().length === 0) {
            setTitleError(true);
            return;
        }
        setTitleError(false);
        setImageError(false);
        setDisabled(true);
        const newPost = {
            username: user.name,
            title,
            description,
            hashtags,
            innerImages
        };
        if (file) {
            try {
                const compressedFile = await imageCompression(
                    file,
                    compressionOptions
                );
                const data = new FormData();
                const fileName = Date.now() + file.name;
                data.append("name", fileName);
                data.append("image", compressedFile);
                const normalizedFileName = await axios.post("/api/image", data);
                newPost.photo = normalizedFileName.data.payload;
            } catch (err) {
                setImageError(true);
                setFile(null);
                setDisabled(false);
                return;
            }
        }
        try {
            const res = await axios.post("/api/post", newPost);
            window.location.replace("/post/" + res.data.id);
        } catch (err) {}
    };

    const link = Quill.import("formats/link");
    link.sanitize = function (url) {
        // quill by default creates relative links if scheme is missing.
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            return `https://${url}`;
        }
        return url;
    };

    const InlineBlot = Quill.import('blots/embed');
    class ImageBlot extends InlineBlot {
        static create(data) {
            const node = super.create(data);
            node.setAttribute('src', data.src);
            node.setAttribute('alt', data.custom);
            return node;
        }
        //   static value(domNode) {
        // 		const { src, custom } = domNode.dataset;
        // 		return { src, custom };
        // 	}
    }
    ImageBlot.blotName = 'imageBlot';
    ImageBlot.className = 'image-blot';
    ImageBlot.tagName = 'img';
    Quill.register({ 'formats/imageBlot': ImageBlot });

    // Editor ref
    const quill = useRef();

    const imageHandler = useCallback(() => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
    
        // When a file is selected
        input.onchange = () => {
          const file = input.files[0];
          const reader = new FileReader();
    
          // Read the selected file as a data URL
          reader.onload = async () => {
            try {
                const compressedFile = await imageCompression(
                    file,
                    compressionOptions
                );
                const data = new FormData();
                const fileName = "inner" + Date.now() + file.name;
                data.append("name", fileName);
                data.append("image", compressedFile);
                const normalizedFileName = await axios.post("/api/image", data);
                const innerNormalizedFileName = normalizedFileName.data.payload;
                setInnerImages((prevItems) =>
                    [ // with a new array
                        ...prevItems, // that contains all the old items
                        { name: innerNormalizedFileName } // and one new item at the end
                    ]
                );

                const quillEditor = quill.current.getEditor();

                // Get the current selection range and insert the image at that index
                const range = quillEditor.getSelection(true);
                
                quillEditor.insertEmbed(range.index, 'imageBlot', {
                    src: PF + innerNormalizedFileName, // any url
                    custom: innerNormalizedFileName, // any custom attr
                  }, 'user');
                quillEditor.setSelection(null);
            } catch (err) {
                return;
            }
          };
    
          reader.readAsDataURL(file);
        };
      }, [compressionOptions, PF]);

    const Modules = {
        toolbar: {
            container: [[{ header: "2" }], ["bold", "italic", "underline"], ["link", "image"], ["clean"]],
            handlers: {
                image: imageHandler,
            },
        }
    };

    const Formats = ["header", "bold", "italic", "link", "imageBlot", "underline"];  

    return (
        <div className="write">
            {file && (
                <img
                    className="writeImg"
                    src={URL.createObjectURL(file)}
                    alt=""
                />
            )}
            <form className="writeForm" onSubmit={handleSubmit}>
                <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                        <i className="writeIcon fas fa-plus"></i>
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                    <input
                        type="text"
                        placeholder="Заголовок"
                        className="writeInput"
                        autoFocus={true}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="writeFormGroup">
                    {titleError && (
                        <span style={{ color: "red", marginTop: "10px" }}>
                            Заголовок не может быть пустым
                        </span>
                    )}
                </div>
                <div className="writeFormGroup">
                    {imageError && (
                        <span style={{ color: "red", marginTop: "10px" }}>
                            Не удалось загрузить изображение. Доступны для
                            загрузки только изображения с расширением .jpg,
                            .jpeg, .png и соотношением сторон от 4:5 до 1.9:1.
                            Попробуйте загрузить другое изображение
                        </span>
                    )}
                </div>
                <div className="writeFormGroup">
                    <ReactQuill
                        ref={(el) => (quill.current = el)}
                        className="writeInput writeText"
                        placeholder="Расскажите Вашу историю..."
                        modules={Modules}
                        formats={Formats}
                        onChange={(e) => setDesc(e)}
                        theme="bubble"
                    />
                </div>
                <div className="writeFormGroup">
                    <textarea
                        placeholder='Укажите хэштеги через пробел (максимум 10), например: "наукозация наука science"'
                        type="text"
                        className="writeInput writeHashtags"
                        onChange={(e) => setHashtags(e.target.value)}
                    ></textarea>
                </div>
                <button className="writeSubmit" type="submit">
                    {disabled ? "Публикуем..." : "Опубликовать"}
                </button>
            </form>
        </div>
    );
}
